import React from 'react';
import ReactDOM from 'react-dom';
import VSCodeAuth from './VSCodeAuth/index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComingSoon from './ComingSoon';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Routes>
          <Route path="/vscode-auth" element={<VSCodeAuth />} />
          <Route path="/*" element={<ComingSoon />} />
        </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
