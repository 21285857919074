import React from 'react';
import { CSSProperties } from 'react';
import { Box, CssBaseline, Link, makeStyles, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/free-solid-svg-icons';

const ComingSoon = () => {
  const styles: { [key: string]: CSSProperties } = {
    container: {
      textAlign: 'center',
      padding: '50px',
      backgroundColor: '#f0f0f0',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    header: {
      color: '#2a2a2a',
      marginBottom: '0px',
      fontSize: '32px'
    },
    text: {
      color: '#666666',
      fontSize: '17px',
    },
    icon: {
      color: '#EE7A30',
      fontSize: '65px',
      marginBottom: '20px'
    }
  };

  return (
    <div style={styles.container}>
        <CssBaseline />
        <FontAwesomeIcon icon={faTools} style={styles.icon} />
        <h1 style={styles.header}>Coming Soon</h1>
        <p style={styles.text}>We're excited to show you what we're working on. Stay tuned!</p>
    </div>
  );
};

export default ComingSoon;
